export function useQuery(str) {
  let queryString = str || window.location.search || "";
  let keyValPairs = [];
  let params = {};

  queryString = queryString.replace(/.*?\?/, "");

  if (queryString.length) {
    keyValPairs = queryString.split("&");
    for (let pairNum in keyValPairs) {
      const key = keyValPairs[pairNum].split("=")[0];

      if (!key[0]?.length) continue;
      if (typeof params[key] === "undefined") params[key] = [];
      params[key] = keyValPairs[pairNum].split("=")[1];
    }
  }

  return params;
}
